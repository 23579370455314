.article-text {

    line-height: 1.8;

    a {
        color: #000;
    }

    p {
        margin-bottom: 2rem;
    }

    p:first-child:first-letter {
            float: left;
            font-size: 75px;
            line-height: 60px;
            padding-top: 4px;
            padding-right: 8px;
            padding-left: 3px;

    }
}


@media only screen and (max-width: 500px) {
    body,
    html {
        font-size: 12px;
    }
}